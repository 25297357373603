import React from 'react'
import { Helmet } from 'react-helmet'
import { Link, graphql } from 'gatsby'
import Layout from '../../components/Layout'
import EcoCard from '../../components/EcoCard'


const EconomiserPage = ({
  data: {
    site: {
      siteMetadata: { title },
    },
  },
}) => (
  <Layout>
    <Helmet title={`Économiser, épargner et investir avec ces sites | ${title}`} />
    <header className="main-header front-header">
      <div className="main-header-text">
        <h1 className="main-h-text-1">Sites recommandés par EpargnerEnsemble</h1>
        <h2 className="main-h-text-2">Les outils et sites que nous utilisons pour épargner et investir ainsi que faire des économies.</h2>
      </div>
    </header>
    <main className="category investissement">
      <div className="breadcrumbs transparent">
        <div className="breadcrumb-links">
          <Link className="bread-item" to="/">
            <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 22"><path d="M21 12v10h-6v-6H9v6H3V12H0L12 0l12 12zm-1-5.907V1h-3v2.093z"></path></svg>
          </Link>
            ❯
          <Link className="bread-item on" to="#">Économiser</Link>
        </div>
      </div>
      <div className="ecocards">
      <EcoCard 
          info={{
            title: "BitPanda",
            icon: "/img/bitpanda.png",
            categorie: "Crypto",
            desc: "Pour acheter des Bitcoins et autre crypto de manière simple et sécurisé. Achat possible dès 1€.",
            link: "https://epargnerensemble.fr/bitpanda"
          }}
        />
      <EcoCard 
          info={{
            title: "Boursorama",
            icon: "/img/boursorama.png",
            categorie: "Investissement",
            desc: "Banque en ligne avec un bon PEA et assurance vie. Bonus jusqu'à 130€ à l'inscription",
            link: "https://ouvrir-un-compte.boursorama-banque.com/landing/parrainage?code_parrain=ENAR9309&picture=lou0x53i9d1rqMWPXW6pf4EYDRTdYhbCSY454idqXNK8ggT6p-b8Apgg22BRhT1lnnkJcWkP3al9iPL_v0EZ-1-jQTW2X964IKMQa0Bh9HItX8mrYN5FtJgs4UJeGzVV8EzymXjfmw7ZdK5_67Bx16RTLv1l-15"
          }}
        />
        <EcoCard 
          info={{
            title: "Trade Republic",
            icon: "/img/traderepublic.png",
            categorie: "Investissement",
            desc: "Un Compte Titre pour investir en bourse à un prix transparent 1€. Recevez une action gratuite d'un valeur jusqu'à 200€.",
            link: "https://epargnerensemble.fr/traderepublic"
          }}
        />
        <EcoCard 
          info={{
            title: "Degiro",
            icon: "/img/degiro.png",
            categorie: "Investissement",
            desc: "Un Compte Titre pour investir en bourse et acheter vos actions sans restrictions. Frais très faibles.",
            link: "https://epargnerensemble.fr/degiro"
          }}
        />
        <EcoCard 
          info={{
            title: "Yomoni",
            icon: "/img/yomoni.png",
            categorie: "Ass. Vie",
            desc: "Assurance vie en gestion pilotée du type robo-advisor. Choisis un niveau de risque et laisse Yomoni investir ton argent.",
            link: "https://epargnerensemble.fr/yomoni"
          }}
        />
        <EcoCard 
          info={{
            title: "Linxea",
            icon: "/img/linxea.png",
            categorie: "Ass. Vie",
            desc: "Assurance vie avec l'une des meilleures performance du marché. Nous utilisons celle appellée 'Avenir'",
            link: "https://epargnerensemble.fr/linxea"
          }}
        />
        <EcoCard 
          info={{
            title: "Nalo",
            icon: "/img/nalo.png",
            categorie: "Ass. Vie",
            desc: "Assurance vie en gestion pilotée, qui permet de faire une simulation avant de souscrire",
            link: "https://epargnerensemble.fr/nalo"
          }}
        />
        <EcoCard 
          info={{
            title: "Lemonade",
            icon: "/img/lemonade.png",
            categorie: "Assurance",
            desc: "Une assurance habitation à partir de 4€ par mois. Devis gratuit pour comparer avec votre contrat actuel.",
            link: "https://epargnerensemble.fr/lemonade"
          }}
        />
        <EcoCard 
          info={{
            title: "Cashbee",
            icon: "/img/cashbee.png",
            categorie: "Épargne",
            desc: "Vous aide à épargner et propose un compte d'épargne avec un taux attractif.",
            link: "https://epargnerensemble.fr/cashbee"
          }}
        />
        
        
        
        
      </div>
    </main>
  </Layout>
)

export default EconomiserPage

export const economiserPageQuery = graphql`
  query EconomiserQuery {
    site {
      siteMetadata {
        title
      }
    }
  }
`
