import React from 'react'

//const Investissement = ({ info }) => {
  class EcoCard extends React.Component {

    render() {
  const { icon, title, categorie, desc, link } = this.props.info;
  return(
    <div className="ecocard">
      <div className="top">
        <img src={icon} alt="Icon du site" />
      </div>
      <div className="mid-top">
        <div className="mid-top-top">
          <div className="left">Nom</div>
          <div className="right">Categorie</div>
        </div>
        <div className="mid-top-bot">
          <div className="left">{title}</div>
          <div className="right">{categorie}</div>
        </div>
      </div>
      <div className="mid-bot">
        <p>{desc}</p>
      </div>
      <div className="bot">
        <a href={link} rel="sponsored" target="_blank">
          <div className="cta-main">Visiter ❯❯</div></a>
      </div>
    </div>
  )}

}

export default EcoCard